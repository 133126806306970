import React from "react";
import styled from "styled-components";
import { Accordion } from "./Accordion";
import { Adobeacrobatreader } from "@styled-icons/simple-icons/Adobeacrobatreader";
import { BasicAccordion } from "./BasicAccordion";
import { GuideAccordion } from "./GuideAccordion";
import { InputAccordion } from "./InputAccordion";
import { ChevronLeft } from "@styled-icons/boxicons-solid/ChevronLeft";
import { ReportContents, Description, Info } from "./apiTypes";
import {
  BeamSize,
  BeamType,
  Downstand,
  Putty,
  ShelterDuringConstruction,
  SlabType,
  SurfaceStructure,
  SurfacingMaterialInput,
} from "../enum";
import { SeverityNote } from "./SeverityNote";
import { Sections } from "./Main";

type ReportProps = {
  back: () => void;
  sections: Sections[];
  toggleSection: (section: Sections) => void;
  beamType: BeamType;
  beamSize: BeamSize;
  downstand: Downstand;
  putty: Putty;
  surfacingMaterialInput: SurfacingMaterialInput;
  surfaceStructure: SurfaceStructure;
  slabType: SlabType;
  shelterDuringConstruction: ShelterDuringConstruction;
  reportContents: ReportContents;
  siteName: string;

  loading: boolean;
  reportUrl: string | null;
  requestPdf: () => void;
};

const Breakpoint = "1080px";

export function Report(props: ReportProps) {
  const sections = props.sections;

  const toggleSection = props.toggleSection;

  const areaSelection = (
    <Container>
      <Text>Raportin sisältö</Text>
      <Label>
        <input
          checked
          type="checkbox"
          onChange={() => toggleSection("overview")}
        />
        Rakenteen yleiskuvaus
      </Label>
      <Label>
        <input
          type="checkbox"
          checked={sections.includes("beam")}
          onChange={() => toggleSection("beam")}
        />
        Palkin kuvaus
      </Label>
      <Label>
        <input
          type="checkbox"
          checked={sections.includes("structureType")}
          onChange={() => toggleSection("structureType")}
        />
        Rakennetyypin kuvaus
      </Label>
      <Label>
        <input
          type="checkbox"
          checked={sections.includes("surfacingMaterial")}
          onChange={() => toggleSection("surfacingMaterial")}
        />
        Pintamateriaalin kuvaus
      </Label>
      <SecondLabel>
        <input
          disabled={!sections.includes("surfacingMaterial")}
          type="checkbox"
          checked={sections.includes("info3")}
          onChange={() => toggleSection("info3")}
        />
        Lisätietoja pintamateriaalin vesihöyrytiiveyden vaikutuksesta
      </SecondLabel>
      <SecondLabel>
        <input
          disabled={!sections.includes("surfacingMaterial")}
          type="checkbox"
          checked={sections.includes("info1")}
          onChange={() => toggleSection("info1")}
        />
        Lisätietoja tasoitteesta
      </SecondLabel>
      <Label>
        <input
          type="checkbox"
          checked={sections.includes("worksite")}
          onChange={() => toggleSection("worksite")}
        />
        Työmaaohjeistus
      </Label>
      <SecondLabel>
        <input
          disabled={!sections.includes("worksite")}
          type="checkbox"
          checked={sections.includes("info2")}
          onChange={() => toggleSection("info2")}
        />
        Lisätietoja mittaussyvyyksistä
      </SecondLabel>
    </Container>
  );

  const button = props.reportUrl ? (
    <Download
      href={props.reportUrl}
      style={{ textDecoration: "none", color: "#0072CE" }}
      download={"report.pdf"}
    >
      <AdobeIcon style={{ color: "#ffffff" }} />
      Lataa PDF
    </Download>
  ) : (
    <PdfButton disabled={props.loading} onClick={props.requestPdf}>
      {props.loading ? "Odota.." : "Luo PDF"}
    </PdfButton>
  );

  return (
    <>
      <Breadcrump>
        <BackButton onClick={props.back}>
          <ChevronLeftIcon /> Takaisin parametreihin
        </BackButton>
      </Breadcrump>
      <SectionSelect>{areaSelection}</SectionSelect>
      <Content>
        <div>
          <InputAccordion
            title="Työn nimi"
            date={props.reportContents.date}
            included
          >
            {props.reportContents.siteName}
          </InputAccordion>

          <Accordion
            severityCount={4}
            severity={getSummaryValue(
              props.reportContents.reportContents,
              "overview"
            )}
            title="Rakenteen yleiskuvaus"
            included
          >
            <SeverityNote
              severityCount={4}
              severity={getSummaryValue(
                props.reportContents.reportContents,
                "overview"
              )}
              title={getSummaryTexts(
                props.reportContents.reportContents,
                "overview"
              )}
            />
            {getTexts(props.reportContents.reportContents, "overview").map(
              (str, i) => (
                <div key={i} dangerouslySetInnerHTML={{ __html: str }} />
              )
            )}
          </Accordion>

          <Accordion
            severity={getSummaryValue(
              props.reportContents.reportContents,
              "beam"
            )}
            title="Palkin kuvaus"
            included={sections.includes("beam")}
            toggleInclude={() => toggleSection("beam")}
          >
            <AccordionDiv>
              {getBeamTypeText(props.beamType)}
              {", "}
              {getBeamSizeText(props.beamSize)}
              {", "}
              {getDownstandText(props.downstand)}
            </AccordionDiv>
            <SeverityNote
              severity={getSummaryValue(
                props.reportContents.reportContents,
                "beam"
              )}
              title={getSummaryTexts(
                props.reportContents.reportContents,
                "beam"
              )}
            />
            {getTexts(props.reportContents.reportContents, "beam").map(
              (str, i) => (
                <div key={i} dangerouslySetInnerHTML={{ __html: str }} />
              )
            )}
          </Accordion>

          <Accordion
            severity={getSummaryValue(
              props.reportContents.reportContents,
              "structureType"
            )}
            title="Rakennetyypin kuvaus"
            included={sections.includes("structureType")}
            toggleInclude={() => toggleSection("structureType")}
          >
            <AccordionDiv>
              {getSlabTypeText(props.slabType)}
              {", "}
              {getSurfaceStructureText(props.surfaceStructure)}
              {", "}

              {getShelterDuringConstructionText(
                props.shelterDuringConstruction
              )}
            </AccordionDiv>
            <SeverityNote
              severity={getSummaryValue(
                props.reportContents.reportContents,
                "structureType"
              )}
              title={getSummaryTexts(
                props.reportContents.reportContents,
                "structureType"
              )}
            />

            {getTexts(props.reportContents.reportContents, "structureType").map(
              (str, i) => (
                <div key={i} dangerouslySetInnerHTML={{ __html: str }} />
              )
            )}
          </Accordion>

          <Accordion
            severity={getSummaryValue(
              props.reportContents.reportContents,
              "surfacingMaterial"
            )}
            title="Pintamateriaalin kuvaus"
            included={sections.includes("surfacingMaterial")}
            toggleInclude={() => toggleSection("surfacingMaterial")}
          >
            <AccordionDiv>
              {getPuttyText(props.putty)}
              {", "}
              {getSurfacingMaterialInputText(props.surfacingMaterialInput)}
            </AccordionDiv>
            <SeverityNote
              severity={getSummaryValue(
                props.reportContents.reportContents,
                "surfacingMaterial"
              )}
              title={getSummaryTexts(
                props.reportContents.reportContents,
                "surfacingMaterial"
              )}
            />
            {getTexts(
              props.reportContents.reportContents,
              "surfacingMaterial"
            ).map((str, i) => (
              <div key={i} dangerouslySetInnerHTML={{ __html: str }} />
            ))}
            <BasicAccordion
              title="Sisällytä lisätietoja pintamateriaalin vesihöyrytiiveyden vaikutuksesta"
              included={sections.includes("info3")}
              toggleInclude={() => toggleSection("info3")}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: getInfoTexts(props.reportContents.infos, "info3"),
                }}
              />
            </BasicAccordion>
            <BasicAccordion
              title="Sisällytä lisätietoja tasoitteesta"
              included={sections.includes("info1")}
              toggleInclude={() => toggleSection("info1")}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: getInfoTexts(props.reportContents.infos, "info1"),
                }}
              />
            </BasicAccordion>
          </Accordion>

          <GuideAccordion
            severity={getSummaryValue(
              props.reportContents.reportContents,
              "worksite"
            )}
            title="Työmaaohjeistus"
            included={sections.includes("worksite")}
            toggleInclude={() => toggleSection("worksite")}
          >
            {getTexts(props.reportContents.reportContents, "worksite").map(
              (str, i) => (
                <div key={i} dangerouslySetInnerHTML={{ __html: str }} />
              )
            )}
            <BasicAccordion
              title="Sisällytä lisätietoja mittaussyvyyksistä"
              included={sections.includes("info2")}
              toggleInclude={() => toggleSection("info2")}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: getInfoTexts(props.reportContents.infos, "info2"),
                }}
              />
            </BasicAccordion>
          </GuideAccordion>
        </div>
        <Footer>{button}</Footer>
      </Content>
      <Right>{button}</Right>
    </>
  );
}

function getSummaryValue(contents: Description[], section: string): number {
  return contents.find((desc) => desc.id === section)!.summaryValue;
}

function getInfoTexts(contents: Info[], section: string): string {
  return contents.find((desc) => desc.id === section)!.content;
}

function getSummaryTexts(contents: Description[], section: string): string {
  return contents.find((desc) => desc.id === section)!.summaryText;
}

function getTexts(contents: Description[], section: string): string[] {
  return contents.find((desc) => desc.id === section)!.texts;
}

function getBeamTypeText(beamType: BeamType): string {
  if (beamType === BeamType.middleBeam) {
    return "Keskipalkki";
  } else if (beamType === BeamType.middleBeamWithFormwork) {
    return "Keskipalkki valukaukalolla";
  } else if (beamType === BeamType.edgeBeam) {
    return "Reunapalkki";
  } else {
    return "Reunapalkki valukaukalolla";
  }
}

function getBeamSizeText(beamSize: BeamSize): string {
  if (beamSize === BeamSize.small) {
    return "Pieni (<D32)";
  } else if (beamSize === BeamSize.typical) {
    return "Tyypillinen (D32=<….=<D40)";
  } else if (beamSize === BeamSize.high) {
    return "Korkea (D40<…=<D50)";
  } else {
    return "Suuri (>D50)";
  }
}

function getDownstandText(downstand: Downstand): string {
  if (downstand === Downstand.noOrSmall) {
    return "Ei leuankorotusta tai pieni leuankorotus (<50 mm)";
  } else {
    return "Leuankorotus (>=50)";
  }
}

function getPuttyText(putty: Putty): string {
  if (putty === Putty.typicalLevellingConcrete) {
    return "Tavanomainen ohut tasoite 1-2 mm";
  } else {
    return "Matala-alkaalinen tasoite 5 mm";
  }
}

function getSurfacingMaterialInputText(
  surfacingMaterialInput: SurfacingMaterialInput
): string {
  if (
    surfacingMaterialInput ===
    SurfacingMaterialInput.homogenicPublicSpacePlasticCarpet
  ) {
    return "Homogeeninen julkisen tilan muovimatto (50 m < sd < 100 m)";
  } else if (
    surfacingMaterialInput ===
    SurfacingMaterialInput.heterogenicPublicSpacePlasticCarpet
  ) {
    return "Heterogeeninen julkisen tilan muovimatto (30 m < sd < 40 m)";
  } else if (
    surfacingMaterialInput === SurfacingMaterialInput.hardwoodVinylPlank
  ) {
    return "Joustovinyylimuovimatto (8 m < sd < 10 m)";
  } else if (
    surfacingMaterialInput ===
    SurfacingMaterialInput.textileCarpetClosedStructure
  ) {
    return "Tekstiililaatta, tiivis alusta";
  } else if (
    surfacingMaterialInput === SurfacingMaterialInput.textileCarpetOpenStructure
  ) {
    return "Tekstiililaatta, avoin alusta";
  } else if (
    surfacingMaterialInput === SurfacingMaterialInput.hardwoodFloorGlued
  ) {
    return "Parketti (alustaan liimattu";
  } else if (surfacingMaterialInput === SurfacingMaterialInput.vinylFlooring) {
    return "Parketti, vinyylilankku yms. (kelluva)";
  } else if (surfacingMaterialInput === SurfacingMaterialInput.linoleum) {
    return "Linoleum";
  } else if (
    surfacingMaterialInput === SurfacingMaterialInput.paintOrMassSurface
  ) {
    return "Maali- tai massapinnoite (epoksi, akryyli yms)";
  } else {
    return "Keraaminen laatta";
  }
}

function getSurfaceStructureText(surfaceStructure: SurfaceStructure): string {
  if (surfaceStructure === SurfaceStructure.levellingConcrete) {
    return "Ohut tasoite";
  } else if (surfaceStructure === SurfaceStructure.thinToppingConcrete) {
    return "Ohut pintavalu 50...80 mm";
  } else if (surfaceStructure === SurfaceStructure.thickToppingConcrete) {
    return "Paksu pintavalu > 80 mm";
  } else {
    return "Kelluva pintavalu";
  }
}

function getShelterDuringConstructionText(
  shelterDuringConstruction: ShelterDuringConstruction
): string {
  if (
    shelterDuringConstruction ===
    ShelterDuringConstruction.frameExposedForWeather
  ) {
    return "Runkovaihe säälle altis";
  } else {
    return "Runkovaihe suojattu sateelta";
  }
}

function getSlabTypeText(slabtype: SlabType): string {
  if (slabtype === SlabType.hollowCore) {
    return "Ontelolaatta";
  } else if (slabtype === SlabType.filligran) {
    return "Kuorilaatta/Paikallavalu";
  } else {
    return "Puulaatta";
  }
}

const AdobeIcon = styled(Adobeacrobatreader)`
  width: 20px;
  margin-left: 5px;
  background-color: #0072ce;
  padding: 2px;
  margin-right: 10px;
  border-radius: 4px;
`;

const AccordionDiv = styled.div`
  font-style: italic;
  font-size: 14px;
  margin-bottom: 10px;
`;

const ChevronLeftIcon = styled(ChevronLeft)`
  width: 20px;
  margin-right: 10px;
  float: left;
  @media (max-width: 370px) {
    width: 15px;
  }
`;
const Breadcrump = styled.nav`
  background: #00b0b9;
  grid-column: left / right-end;
  grid-row: breadcrump;
  padding: 8px 0px 20px 40px;
`;

const BackButton = styled.button`
  border: none;
  background-color: transparent;
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
  color: #ffffff;
  cursor: pointer;

  @media (max-width: 370px) {
    font-size: 14px;
  }
`;

const Text = styled.p`
  font-size: 14 px;
  font-weight: bold;
  color: #333f48;
`;

const Container = styled.div`
  border: 1px solid #e3e6e5;
  margin: 20px 20px 20px 20px;
  padding: 10px 20px 10px 10px;
`;

const Content = styled.main`
  background: #ffffff;
  grid-column: left / right-end;
  grid-row: content;
  overflow-y: scroll;
  padding: 20px 0 10px 0;
  margin-bottom: 100px;

  @media (min-width: ${Breakpoint}) {
    display: block;
    grid-column: main;
  }
`;

const SecondLabel = styled.label`
  display: block;
  margin-bottom: 10px;
  margin-top: 10px;

  font-size: 14px;
  padding-left: 30px;
  color: #333f48;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 10px;
  margin-top: 10px;
  font-size: 14px;
  color: #333f48;
`;

const SectionSelect = styled.aside`
  display: none;
  @media (min-width: ${Breakpoint}) {
    display: block;
    grid-row: content;
    grid-column: left / main;
  }
`;

const Footer = styled.footer`
  position: fixed;
  width: 100%;
  bottom: 0;
  background-color: #ffff;
  border-top: 1px solid #e3e6e5;
  overflow: hidden;

  @media screen and (min-width: 1080px) {
    display: none;
  }
`;

const Download = styled.a`
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
  color: white;
  background-color: #ffffff;
  border-right: 1px solid #e3e6e5;
  border-left: 1px solid #e3e6e5;
  width: 45%;
  padding: 20px 0 20px 10px;
  cursor: pointer;
  float: right;
  @media (min-width: ${Breakpoint}) {
    width: 100%;
    margin-bottom: 10px;
    border: 1px solid #e3e6e5;
    padding-left: 25px;
  }
`;

const PdfButton = styled.button`
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
  color: white;
  background-color: #96c11e;
  width: 45%;
  border: none;
  padding: 20px 0 20px 0;
  cursor: pointer;
  float: right;
`;

const Right = styled.aside`
  background-color: #ffffff;
  display: none;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0 30px 0 0;
  margin-top: 20px;
  @media (min-width: ${Breakpoint}) {
    display: flex;
    grid-row: content;
    grid-column: right / right-end;
  }
  ${PdfButton} {
    width: 100%;
    margin-bottom: 10px;
  }
`;

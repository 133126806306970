import React from "react";
import styled from "styled-components";

import { Text } from "./sharedStyles/Text";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { BasicButton } from "./sharedStyles/BasicButton";

import {
  BeamSize,
  BeamType,
  Downstand,
  Putty,
  SurfacingMaterialInput,
  SurfaceStructure,
  ShelterDuringConstruction,
  SlabType,
} from "../enum";

interface DropdownProps {
  showError?: boolean;
}

type Props = {
  resetFields: () => void;
  onCalculate: () => void;
  loading: boolean;
  submitPressed: boolean;

  siteName: string | null;
  onSiteNameChanges: (name: string) => void;

  beamType: BeamType | null;
  onBeamTypeChanges: (beamType: BeamType) => void;

  beamSize: BeamSize | null;
  onBeamSizeChanges: (beamSize: BeamSize) => void;

  downstand: Downstand | null;
  onDownstandChanges: (downstand: Downstand) => void;

  putty: Putty | null;
  onPuttyChanges: (putty: Putty) => void;

  surfacingMaterialInput: SurfacingMaterialInput | null;
  onSurfacingMaterialInputChanges: (
    surfacingMaterialInput: SurfacingMaterialInput
  ) => void;

  surfaceStructure: SurfaceStructure | null;
  onSurfaceStructureChanges: (surfaceStructure: SurfaceStructure) => void;

  slabType: SlabType | null;
  onSlabTypeChanges: (slabType: SlabType) => void;

  shelterDuringConstruction: ShelterDuringConstruction | null;
  onShelterDuringConstructionChanges: (
    shelterDuringConstruction: ShelterDuringConstruction
  ) => void;
};

const BeamTypeOptions = [
  { label: "Valitse", value: "" },
  { label: "Keskipalkki", value: BeamType.middleBeam },
  {
    label: "Keskipalkki valukaukalolla",
    value: BeamType.middleBeamWithFormwork,
  },
  { label: "Reunapalkki", value: BeamType.edgeBeam },
  { label: "Reunapalkki valukaukalolla", value: BeamType.edgeBeamWithFormwork },
];
const BeamSizeOptions = [
  { label: "Valitse", value: "" },
  { label: "Pieni (<D32)", value: BeamSize.small },
  { label: "Tyypillinen (D32=<….=<D40)", value: BeamSize.typical },
  { label: "Korkea (D40<…=<D50)", value: BeamSize.high },
  { label: "Suuri (>D50)", value: BeamSize.large },
];

const DownstandOptions = [
  { label: "Valitse", value: "" },
  {
    label: "Ei leuankorotusta tai pieni leuankorotus (<50 mm)",
    value: Downstand.noOrSmall,
  },
  { label: "Leuankorotus (>=50)", value: Downstand.hasDownstand },
];

const PuttyOptions = [
  { label: "Valitse", value: "" },
  {
    label: "Tavanomainen ohut tasoite, 1-2 mm",
    value: Putty.typicalLevellingConcrete,
  },
  { label: "Matala-alkaalinen tasoite, 5 mm", value: Putty.lowAlkali },
];

const SurfacingMaterialInputOptions = [
  { label: "Valitse", value: "" },
  {
    label: "Homogeeninen julkisen tilan muovimatto (50 m < sd < 100 m)",
    value: SurfacingMaterialInput.homogenicPublicSpacePlasticCarpet,
  },
  {
    label: "Heterogeeninen julkisen tilan muovimatto (30 m < sd < 40 m)",
    value: SurfacingMaterialInput.heterogenicPublicSpacePlasticCarpet,
  },
  {
    label: "Joustovinyylimuovimatto (8 m < sd < 10 m)",
    value: SurfacingMaterialInput.hardwoodVinylPlank,
  },
  {
    label: "Tekstiililaatta, tiivis alusta",
    value: SurfacingMaterialInput.textileCarpetClosedStructure,
  },
  {
    label: "Tekstiililaatta, avoin alusta",
    value: SurfacingMaterialInput.textileCarpetOpenStructure,
  },
  {
    label: "Parketti (alustaan liimattu)",
    value: SurfacingMaterialInput.hardwoodFloorGlued,
  },
  {
    label: "Parketti, vinyylilankku yms. (kelluva)",
    value: SurfacingMaterialInput.vinylFlooring,
  },
  { label: "Linoleum", value: SurfacingMaterialInput.linoleum },
  {
    label: "Maali- tai massapinnoite (epoksi, akryyli yms)",
    value: SurfacingMaterialInput.paintOrMassSurface,
  },
  { label: "Keraaminen laatta", value: SurfacingMaterialInput.ceramicTile },
];

const SurfaceStructureOptions = [
  { label: "Valitse", value: "" },
  {
    label: "Ohut tasoite",
    value: SurfaceStructure.levellingConcrete,
  },
  {
    label: "Ohut pintavalu 50...80 mm",
    value: SurfaceStructure.thinToppingConcrete,
  },
  {
    label: "Paksu pintavalu > 80 mm",
    value: SurfaceStructure.thickToppingConcrete,
  },
  {
    label: "Kelluva pintavalu",
    value: SurfaceStructure.insulatedToppingConcrete,
  },
];

const ShelterDuringConstructionOptions = [
  { label: "Valitse", value: "" },
  {
    label: "Runkovaihe säälle altis ",
    value: ShelterDuringConstruction.frameExposedForWeather,
  },
  {
    label: "Runkovaihe suojattu sateelta",
    value: ShelterDuringConstruction.frameCovered,
  },
];

const SlabTypeOptions = [
  { label: "Valitse", value: "" },
  {
    label: "Ontelolaatta ",
    value: SlabType.hollowCore,
  },
  {
    label: "Kuorilaatta/Paikallavalu",
    value: SlabType.filligran,
  },
  {
    label: "Puulaatta",
    value: SlabType.wooden,
  },
];

export function Parameter(props: Props) {
  return (
    <>
      <HeaderText>Parametrit</HeaderText>
      <Parameters>
        <Desktop>
          <InfoText>
            Peikon DELTABEAM®-kosteudenhallintatyökalu auttaa sinua arvioimaan
            projektin mahdolliset kosteusriskit jo suunnitteluvaiheessa. Ohjelma
            tuottaa valmiin raportin, joka ottaa kantaa rakenteiden
            kosteustekniseen turvallisuuteen sekä riskien minimoimiseen. Lisäksi
            raportti sisältää käytännön ohjeistuksen työmaalla tapahtuvaan
            mittaamiseen ja pinnoittamiseen.
          </InfoText>
          <InfoText>
            Lisätietoa kosteudenhallinnasta löydät kotisivuiltamme osoitteesta{" "}
            <a
              href="https://www.peikko.fi/kosteudenhallinta"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#000000" }}
            >
              {" "}
              peikko.fi/kosteudenhallinta
            </a>
          </InfoText>
          <RequiredText>* Pakolliset kentät</RequiredText>
          <TextTitle>Työn nimi</TextTitle>
          <BoxInput>
            <Input
              value={props.siteName ?? ""}
              type="text"
              id="sname"
              name="sitename"
              placeholder="Lisää työmaan, projektin, kerroksen nimi"
              onChange={(event) => {
                props.onSiteNameChanges(event.target.value);
              }}
            />
          </BoxInput>
          <Box>
            <TextTitle>Palkki</TextTitle>
            <Text primary>Palkkityyppi*</Text>
            <PeikkoDropdown
              options={BeamTypeOptions}
              onChange={(val) =>
                props.onBeamTypeChanges(
                  BeamType[val.value as keyof typeof BeamType]
                )
              }
              value={props.beamType ?? ""}
              placeholder="Valitse"
              showError={!props.beamType && props.submitPressed}
            />
            {!props.beamType && props.submitPressed && (
              <ErrorTextDiv>Valitse palkkityyppi</ErrorTextDiv>
            )}
          </Box>
          <Box>
            <Text primary>Palkin koko*</Text>
            <PeikkoDropdown
              options={BeamSizeOptions}
              onChange={(val) =>
                props.onBeamSizeChanges(
                  BeamSize[val.value as keyof typeof BeamSize]
                )
              }
              value={props.beamSize ?? ""}
              placeholder="Valitse"
              showError={!props.beamSize && props.submitPressed}
            />
            {!props.beamSize && props.submitPressed && (
              <ErrorTextDiv>Valitse palkin koko</ErrorTextDiv>
            )}
          </Box>
          <Box>
            <Text primary>Leuankorotus*</Text>
            <PeikkoDropdown
              options={DownstandOptions}
              onChange={(val) =>
                props.onDownstandChanges(
                  Downstand[val.value as keyof typeof Downstand]
                )
              }
              value={props.downstand ?? ""}
              placeholder="Valitse"
              showError={!props.downstand && props.submitPressed}
            />
            {!props.downstand && props.submitPressed && (
              <ErrorTextDiv>Valitse leuankorotus</ErrorTextDiv>
            )}
            <BorderBottom></BorderBottom>
          </Box>

          <TextTitle>Rakennetyyppi</TextTitle>
          <Box>
            <Text primary>Laattatyyppi*</Text>
            <PeikkoDropdown
              options={SlabTypeOptions}
              onChange={(val) =>
                props.onSlabTypeChanges(
                  SlabType[val.value as keyof typeof SlabType]
                )
              }
              value={props.slabType ?? ""}
              placeholder="Valitse"
              showError={!props.slabType && props.submitPressed}
            />
            {!props.slabType && props.submitPressed && (
              <ErrorTextDiv>Valitse laattatyyppi</ErrorTextDiv>
            )}
          </Box>
          <Box>
            <Text primary>Pintarakenne*</Text>
            <PeikkoDropdown
              options={SurfaceStructureOptions}
              onChange={(val) =>
                props.onSurfaceStructureChanges(
                  SurfaceStructure[val.value as keyof typeof SurfaceStructure]
                )
              }
              value={props.surfaceStructure ?? ""}
              placeholder="Valitse"
              showError={!props.surfaceStructure && props.submitPressed}
            />
            {!props.surfaceStructure && props.submitPressed && (
              <ErrorTextDiv>Valitse pintarakenne</ErrorTextDiv>
            )}
          </Box>
          <Box>
            <Text primary>Työaikainen suojaus*</Text>
            <PeikkoDropdown
              options={ShelterDuringConstructionOptions}
              onChange={(val) =>
                props.onShelterDuringConstructionChanges(
                  ShelterDuringConstruction[
                    val.value as keyof typeof ShelterDuringConstruction
                  ]
                )
              }
              value={props.shelterDuringConstruction ?? ""}
              placeholder="Valitse"
              showError={
                !props.shelterDuringConstruction && props.submitPressed
              }
            />
            {!props.shelterDuringConstruction && props.submitPressed && (
              <ErrorTextDiv>Valitse työaikainen suojaus</ErrorTextDiv>
            )}
          </Box>
          <TextTitle>Pintamateriaali</TextTitle>
          <Box>
            <Text primary>Tasoite*</Text>
            <PeikkoDropdown
              options={PuttyOptions}
              onChange={(val) =>
                props.onPuttyChanges(Putty[val.value as keyof typeof Putty])
              }
              value={props.putty ?? ""}
              placeholder="Valitse"
              showError={!props.putty && props.submitPressed}
            />
            {!props.putty && props.submitPressed && (
              <ErrorTextDiv>Valitse tasoite</ErrorTextDiv>
            )}
          </Box>
          <Box>
            <Text primary>Pintamateriaali*</Text>
            <PeikkoDropdown
              options={SurfacingMaterialInputOptions}
              onChange={(val) =>
                props.onSurfacingMaterialInputChanges(
                  SurfacingMaterialInput[
                    val.value as keyof typeof SurfacingMaterialInput
                  ]
                )
              }
              value={props.surfacingMaterialInput ?? ""}
              placeholder="Valitse"
              showError={!props.surfacingMaterialInput && props.submitPressed}
            />
            {!props.surfacingMaterialInput && props.submitPressed && (
              <ErrorTextDiv>Valitse pintamateriaali</ErrorTextDiv>
            )}
            <BorderBottom></BorderBottom>
            {(!props.beamType ||
              !props.beamSize ||
              !props.downstand ||
              !props.putty ||
              !props.surfacingMaterialInput ||
              !props.surfaceStructure ||
              !props.slabType ||
              !props.shelterDuringConstruction) &&
              props.submitPressed && (
                <CenterTextDiv>Täytä puuttuvat kentät!</CenterTextDiv>
              )}
          </Box>

          <BasicButton primary onClick={props.onCalculate}>
            {props.loading ? "Ladataan..." : "Luo raportti"}
          </BasicButton>

          <BasicButton onClick={props.resetFields}>Tyhjennä kentät</BasicButton>
        </Desktop>
      </Parameters>
    </>
  );
}

const CenterTextDiv = styled.div`
  color: red;
  text-align: center;
`;

const ErrorTextDiv = styled.div`
  color: red;
`;

const InfoText = styled.div`
  padding-bottom: 20px;
  font-size: 16px;
`;

const RequiredText = styled.p`
  color: #7c878e;
  font-size: 12px;
`;

const Desktop = styled.div`
  max-width: 800px;
  margin: auto;
`;

const HeaderText = styled.div`
  text-transform: uppercase;
  color: white;
  background-color: #7c878e;

  font-size: 14px;
  font-weight: bold;
  padding-left: 40px;
  padding-top: 10px;
  padding-bottom: 10px;

  grid-column: left / right-end;
  grid-row: breadcrump;
`;

const BorderBottom = styled.div`
  border-bottom: 1px solid #e3e6e5;
  padding-top: 20px;
`;

const PeikkoDropdown = styled(Dropdown)<DropdownProps>`
  .Dropdown-control {
    color: white;
    background: #7c878e;
    font-weight: bold;
    font-size: 14px;
    margin-top: 5px;
    padding: 20px 0 20px 10px;
    border: ${(props) => (props.showError ? "2px solid red" : "")};
  }

  .Dropdown-arrow {
    border-color: white transparent transparent;
    border-width: 12px 7px 0px;
    margin-top: 10px;
  }
`;

const Box = styled.div`
  padding-bottom: 20px;
`;

const BoxInput = styled.div`
  padding-bottom: 20px;
  border-bottom: 1px solid #e3e6e5;
  color: #8e7c7c;
`;

const Input = styled.input`
  width: 100%;
  padding: 18px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #e3e6e5;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 14px;
  color: #7c878e;
  &:focus {
    outline: 2px solid #96c11e;
  }
`;

const TextTitle = styled.h3`
  text-transform: uppercase;
  color: #7c878e;
  font-size: 14px;
  font-weight: bold;
`;

const Breakpoint = "1000px";

const Parameters = styled.div`
  padding: 30px 40px 0 40px;
  margin-bottom: 10px;
  background-color: #fff;
  grid-column: left / right-end;
  grid-row: content;
  overflow-y: scroll;

  @media (min-width: ${Breakpoint}) {
    display: block;
    grid-column: main;
  }
`;

import styled from "styled-components";

type BasicButtonProps = {
  primary?: boolean;
};

export const BasicButton = styled.button<BasicButtonProps>`
  background-color: ${({ primary }) => (primary ? "#96C11E" : "#E3E6E5")};
  float: ${({ primary }) => (primary ? "right" : "left")};
  color: ${({ primary }) => (primary ? "#FFFFFF" : "#7C878E")};
  font-weight: bold;
  font-size: 20px;
  text-transform: uppercase;
  width: 48%;
  padding: 20px;
  margin-bottom: 20px;
  margin-top: 20px;
  border: none;
  cursor: pointer;
  &:hover {
    background-color: ${({ primary }) => (primary ? " #669900" : "#BEC3C7")};
  }
  &:disabled {
    background-color: ${({ primary }) => (primary ? " #BFD977" : "#BFD977")};
  }
`;

export enum BeamType {
  middleBeam = "middleBeam",
  middleBeamWithFormwork = "middleBeamWithFormwork",
  edgeBeam = "edgeBeam",
  edgeBeamWithFormwork = "edgeBeamWithFormwork",
}

export enum BeamSize {
  small = "small",
  typical = "typical",
  high = "high",
  large = "large",
}

export enum Downstand {
  noOrSmall = "noOrSmall",
  hasDownstand = "hasDownstand",
}

export enum SlabType {
  hollowCore = "hollowCore",
  filligran = "filligran",
  wooden = "wooden",
}

export enum SurfaceStructure {
  levellingConcrete = "levellingConcrete",
  thinToppingConcrete = "thinToppingConcrete",
  thickToppingConcrete = "thickToppingConcrete",
  insulatedToppingConcrete = "insulatedToppingConcrete",
}

export enum Putty {
  typicalLevellingConcrete = "typicalLevellingConcrete",
  lowAlkali = "lowAlkali",
}

export enum SurfacingMaterialInput {
  homogenicPublicSpacePlasticCarpet = "homogenicPublicSpacePlasticCarpet",
  heterogenicPublicSpacePlasticCarpet = "heterogenicPublicSpacePlasticCarpet",
  vinylFlooring = "vinylFlooring",
  textileCarpetClosedStructure = "textileCarpetClosedStructure",
  textileCarpetOpenStructure = "textileCarpetOpenStructure",
  hardwoodFloorGlued = "hardwoodFloorGlued",
  hardwoodVinylPlank = "hardwoodVinylPlank",
  linoleum = "linoleum",
  paintOrMassSurface = "paintOrMassSurface",
  ceramicTile = "ceramicTile",
}

export enum ShelterDuringConstruction {
  frameExposedForWeather = "frameExposedForWeather",
  frameCovered = "frameCovered",
}

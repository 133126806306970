import React from "react";
import styled from "styled-components";
import { MinusCircle } from "@styled-icons/boxicons-solid/MinusCircle";
import { PlusCircle } from "@styled-icons/boxicons-solid/";

type Props = {
  title: string;
  children: React.ReactNode;
  included: boolean;
  toggleInclude?: () => void;
  severity: number;
};

export function GuideAccordion(props: Props) {
  return (
    <Wrapper>
      <Header>
        {props.title}
        <IconContainer>
          {props.toggleInclude && (
            <ReportButton onClick={props.toggleInclude}>
              {props.included
                ? "Sisällytetty raporttiin"
                : "Ei sisällytetty raporttiin"}
              {props.included ? (
                <MinusCircleIcon />
              ) : (
                <PlusCircleIcon style={{ color: "#333F48" }} />
              )}
            </ReportButton>
          )}
        </IconContainer>
      </Header>
      <Content>
        {props.included && <ContentDiv>{props.children}</ContentDiv>}
      </Content>
    </Wrapper>
  );
}

const ReportButton = styled.div`
  border: none;
  font-size: 14px;
  color: #333f48;
  text-transform: none;
  font-weight: normal;
  padding-right: 5px;
`;
const ContentDiv = styled.div`
  padding: 20px;
  img {
    max-width: 100%;
  }
`;

const IconContainer = styled.div`
  float: right;
`;

const PlusCircleIcon = styled(PlusCircle)`
  width: 25px;
  margin-left: 5px;
`;

const MinusCircleIcon = styled(MinusCircle)`
  width: 25px;
  margin-left: 5px;
`;

const Content = styled.div`
  padding: 1px;
  background-color: #ffffff;
  border-left: 1px solid #e3e6e5;
  border-bottom: 1px solid #e3e6e5;
  border-right: 1px solid #e3e6e5;
`;

const Header = styled.div`
  /* display: flex; */
  text-transform: uppercase;
  color: #333f48;
  font-size: 14px;
  font-weight: bold;
  align-items: center;
  background-color: #e3e6e5;
  padding: 10px;
  cursor: pointer;
  border: 1px solid #e3e6e5;
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 0 40px 0 40px;
`;

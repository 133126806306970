import React, { useState } from "react";
import { Report } from "./Report";
import { Parameter } from "./Parameter";
import ReactGA from "react-ga4";
import {
  BeamType,
  BeamSize,
  Downstand,
  Putty,
  SurfacingMaterialInput,
  SlabType,
  SurfaceStructure,
  ShelterDuringConstruction,
} from "../enum";
import { ReportContents } from "./apiTypes";

type Props = {
  token: string;
};

export type Sections =
  | "overview"
  | "beam"
  | "structureType"
  | "surfacingMaterial"
  | "worksite"
  | "info1"
  | "info2"
  | "info3";

type View = "parameter" | "report";

export function Main(props: Props) {
  const [sections, setSections] = useState<Sections[]>([
    "overview",
    "beam",
    "structureType",
    "surfacingMaterial",
    "worksite",
  ]);

  // change to localhost for testing
  const urlCreateReport = `${process.env.REACT_APP_API_URL}/api/CreateReport`; 
  const [submitPressed, setSubmitPressed] = useState(false);

  const [loading, setLoading] = useState(false);
  const [view, setView] = useState<View>("parameter");
  const [report, setReport] = useState<ReportContents | null>(null);
  const [siteName, setSiteName] = useState<string | null>(null);
  const [beamType, setBeamType] = useState<BeamType | null>(null);
  const [beamSize, setBeamSize] = useState<BeamSize | null>(null);
  const [downstand, setDownstand] = useState<Downstand | null>(null);
  const [putty, setPutty] = useState<Putty | null>(null);
  const [surfacingMaterialInput, setSurfacingMaterialInput] =
    useState<SurfacingMaterialInput | null>(null);

  const [slabType, setSlabType] = useState<SlabType | null>(null);

  const [surfaceStructure, setSurfaceStructure] =
    useState<SurfaceStructure | null>(null);

  const [shelterDuringConstruction, setShelterDuringConstruction] =
    useState<ShelterDuringConstruction | null>(null);

  const [reportUrl, setReportUrl] = useState<string | null>(null);

  const createReport = async () => {
    const res = await fetch(urlCreateReport, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        authorization: "Bearer " + props.token,
      },
      body: JSON.stringify({
        siteName,
        beamType,
        beamSize,
        downstand,
        putty,
        surfacingMaterialInput,
        shelterDuringConstruction,
        surfaceStructure,
        slabType,
      }),
    });
    if (!res.ok) {
      throw Error();
    }
    return res.json();
  };

  function resetFields() {
    setSiteName(null);
    setBeamType(null);
    setBeamSize(null);
    setDownstand(null);
    setPutty(null);
    setSurfacingMaterialInput(null);
    setSlabType(null);
    setSurfaceStructure(null);
    setShelterDuringConstruction(null);
    setSubmitPressed(false);
  }

  function toggleSection(section: Sections) {
    if (sections.includes(section))
      setSections(sections.filter((val) => val !== section));
    else setSections([...sections, section]);
    setReportUrl(null);
  }

  async function calculate() {
    if (
      !beamType ||
      !beamSize ||
      !downstand ||
      !putty ||
      !surfacingMaterialInput ||
      !surfaceStructure ||
      !slabType ||
      !shelterDuringConstruction
    )
      return setSubmitPressed(true);

    setLoading(true);
    const res = await createReport();

    ReactGA.event({
      category: "Report",
      action: "Generated report",
    });
    setReport(res);

    setView("report");
    setLoading(false);
  }

  async function requestPdf() {
    setLoading(true);

    ReactGA.event({
      category: "Report",
      action: "Generated PDF",
    });

    if (reportUrl) {
      window.URL.revokeObjectURL(reportUrl);
    }
    
    // change to localhost for testing

    const res = await fetch(`${process.env.REACT_APP_API_URL}/api/CreatePdf`, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${props.token}`,
      },

      body: JSON.stringify({
        siteName,
        beamType,
        beamSize,
        downstand,
        putty,
        surfacingMaterialInput,
        shelterDuringConstruction,
        surfaceStructure,
        slabType,
        includedSections: sections,
      }),
      method: "POST",
    });

    const blob = await res.blob();

    setReportUrl(window.URL.createObjectURL(blob));
    setLoading(false);
  }

  if (view === "parameter") {
    return (
      <Parameter
        siteName={siteName}
        beamType={beamType}
        beamSize={beamSize}
        downstand={downstand}
        putty={putty}
        surfacingMaterialInput={surfacingMaterialInput}
        slabType={slabType}
        surfaceStructure={surfaceStructure}
        shelterDuringConstruction={shelterDuringConstruction}
        onBeamTypeChanges={setBeamType}
        onBeamSizeChanges={setBeamSize}
        onDownstandChanges={setDownstand}
        onPuttyChanges={setPutty}
        onSurfacingMaterialInputChanges={setSurfacingMaterialInput}
        onSlabTypeChanges={setSlabType}
        onSurfaceStructureChanges={setSurfaceStructure}
        onShelterDuringConstructionChanges={setShelterDuringConstruction}
        onCalculate={calculate}
        onSiteNameChanges={setSiteName}
        resetFields={resetFields}
        loading={loading}
        submitPressed={submitPressed}
      />
    );
  } else {
    return (
      <Report
        siteName={siteName!}
        beamType={beamType!}
        beamSize={beamSize!}
        downstand={downstand!}
        putty={putty!}
        surfacingMaterialInput={surfacingMaterialInput!}
        slabType={slabType!}
        surfaceStructure={surfaceStructure!}
        shelterDuringConstruction={shelterDuringConstruction!}
        reportContents={report!}
        back={() => setView("parameter")}
        sections={sections}
        toggleSection={toggleSection}
        requestPdf={requestPdf}
        loading={loading}
        reportUrl={reportUrl}
      />
    );
  }
}

import React from "react";
import styled from "styled-components";
import logo from "./assets/Peikko-logo.png";

export function LogoBar() {
  return (
    <Wrapper>
      <Logo src={logo} />
    </Wrapper>
  );
}

const Logo = styled.img`
  padding: 5px 0 0 15px;
`;

const Wrapper = styled.header`
  background-color: #ffffff;
  width: 100%;

  grid-column: left / right-end;
  grid-row: logo;
`;

import styled from "styled-components";

export const Input = styled.input`
  font-size: 16px;
  padding: 15px;
  margin-top: 20px;
  width: 100%;
  background: #ffff;
  border: 1px solid #e3e6e5;
  /* ::placeholder {
    color: #7c878e;
  } */

  &:focus {
    outline: 1px solid #96c11e;
  }
`;

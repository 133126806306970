export function getSeverityColor(severity: number, current: number): string {
  // If we're past the current level, use grey.
  if (current > severity) {
    return "#E3E6E5";
  }

  // Orange
  if (severity === 4) {
    return "#ffa300";
  }

  // Light orange
  if (severity === 3) {
    return "#ffd07e";
  }

  // Light green
  if (severity === 2) {
    return "#BFD977";
  }

  // Default to green
  return "#96C11E";
}

export function getSeverityNormalColor(
  severity: number,
  current: number
): string {
  // If we're past the current level, use grey.
  if (current > severity) {
    return "#E3E6E5";
  }

  // Orange
  if (severity === 3) {
    return "#ffa300";
  }

  // Light orange
  if (severity === 2) {
    return "#ffd07e";
  }

  // Default to green
  return "#96C11E";
}

import styled from "styled-components";

type TextProps = {
  primary?: boolean;
};

export const Text = styled.p<TextProps>`
  color: ${({ primary }) => (primary ? "#333F48" : "#ffff")};
  font-size: ${({ primary }) => (primary ? "14px" : "14px")};
  font-weight: ${({ primary }) => (primary ? "bold" : "regular")};
`;

import React from "react";
import styled from "styled-components";
import {
  getSeverityColor,
  getSeverityNormalColor,
} from "../utils/getSeverityColor";

type SeverityProps = {
  severity: number;
  title: string;
  severityCount?: number;
};

export function SeverityNote({
  severity,
  title,
  severityCount = 3,
}: SeverityProps) {
  let borderColor = getSeverityColor(severity, 1);

  if (severityCount === 3) {
    borderColor = getSeverityNormalColor(severity, 1);
  }
  let boxes: React.ReactNode[] = [];

  for (let current = 1; current < severityCount + 1; current++) {
    let color = getSeverityColor(severity, current);
    if (severityCount === 3) {
      color = getSeverityNormalColor(severity, current);
    }

    const text: string = current === severity ? `${current}` : "";

    boxes.push(
      <Box key={current} color={color}>
        {text}
      </Box>
    );
  }

  return (
    <Wrapper color={borderColor}>
      <Title>{title}</Title>
      <FloatContent>{boxes}</FloatContent>
    </Wrapper>
  );
}

const FloatContent = styled.div`
  display: flex;
  margin-left: auto;
  padding-right: 10px;
`;

type BoxProp = {
  color: string;
};

const Box = styled.div<BoxProp>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  width: 2em;
  height: 2em;
  background-color: ${({ color }) => color};
  color: #000000;
  margin: 0 2px 2px 0;
`;

const Title = styled.h4`
  font-weight: bold;
  font-size: 14px;
  padding: 5px 10px 5px 10px;
  @media (max-width: 550px) {
    padding: 1px 5px 1px 5px;
    margin: 1px;
  }
`;

type WrapperProp = {
  color: string;
};

const Wrapper = styled.div<WrapperProp>`
  border: 1px solid ${({ color }) => color};
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  @media (max-width: 550px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 1px 5px 1px 5px;
    ${FloatContent} {
      margin-left: 0;
    }
  }
`;

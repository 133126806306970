import React, { useState } from "react";
import styled from "styled-components";
import { LogoBar } from "./components/LogoBar";
import { LoginPage } from "../src/components/LoginPage";
import { Main } from "../src/components/Main";
import { LoginInput } from "./components/apiTypes";
import ReactGA from "react-ga4";

// change to localhost for testing
const urlLogin = `${process.env.REACT_APP_API_URL}/api/Login`; 

function App() {
  const [token, setToken] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const login = async (data: LoginInput) => {
    if (!token) {
      try {
        const response = await fetch(urlLogin, {
          method: "POST",
          body: JSON.stringify(data),
        });
        if (!response.ok) {
        }
        const result = await response.json();

        if (result.isSuccess) {
          ReactGA.event({
            category: "User",
            action: "Login",
          });
          setToken(result.token);
        } else {
          setErrorMessage("Kirjautuminen epäonnistui!");
        }
      } catch (error) {
        setErrorMessage("Tapahtui virhe.");
      }
    }
  };

  if (!token) {
    return <LoginPage login={login} errorMessage={errorMessage} />;
  }

  return (
    <Wrapper>
      <Page>
        <LogoBar />
        <Main token={token} />
      </Page>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;

const Page = styled.div`
  background: #ffffff;
  display: grid;
  grid-template-columns: [left] 20% [main] minmax(0, 1fr) [right] 20% [right-end];
  grid-template-rows: [logo] 62px [breadcrump] 40px [content] 1fr;
  min-height: 100%;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
`;

export default App;

import React, { useState } from "react";
import styled from "styled-components";
import { LoginInput } from "./apiTypes";
import logo from "./assets/Peikko-logo.png";
import { Input } from "./sharedStyles/Input";

type Props = {
  login: (loginInput: LoginInput) => void;
  errorMessage: string;
};

export function LoginPage(props: Props) {
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  return (
    <LoginForm
      onSubmit={(e) => {
        e.preventDefault();
        props.login({ username: username, password: password });
      }}
    >
      <Header>
        <Logo src={logo} />
      </Header>
      <Box>
        <Text>Tervetuloa Peikko Moisture Handling Tool - palveluun</Text>
        <Paragraph>
          <InfoText>
            Peikon DELTABEAM®-kosteudenhallintatyökalu auttaa sinua arvioimaan
            projektin mahdolliset kosteusriskit jo suunnitteluvaiheessa. Ohjelma
            tuottaa valmiin raportin, joka ottaa kantaa rakenteiden
            kosteustekniseen turvallisuuteen sekä riskien minimoimiseen. Lisäksi
            raportti sisältää käytännön ohjeistuksen työmaalla tapahtuvaan
            mittaamiseen ja pinnoittamiseen.
          </InfoText>
          Luo helposti kosteusarvioraportteja eri tyyppisille
          rakennusprojekteille.
        </Paragraph>
        <ErrorDiv>{props.errorMessage}</ErrorDiv>
        <Input
          name="username"
          autoComplete="username"
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          placeholder="Käyttäjänimi (email)"
        />
        <Input
          name="password"
          type="password"
          autoComplete="current-password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Salasana"
        />

        <NavLink>
          <a
            href="https://myaccount.peikkodesigner.com/Account/ForgotPassword"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "#0072CE" }}
          >
            Unohditko salasanasi?
          </a>
        </NavLink>
        <Button primary type="submit">
          <ButtonText>Kirjaudu sisään</ButtonText>
        </Button>
        <Paragraph>Eikö sinulla ole tunnusta?</Paragraph>
        <Button>
          <ButtonTextUp>
            <a
              href="https://myaccount.peikkodesigner.com/"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                textDecoration: "none",
                color: "#FFFFFF",
              }}
            >
              Luo Peikko-tunnus
            </a>
          </ButtonTextUp>
        </Button>
      </Box>
    </LoginForm>
  );
}

const InfoText = styled.div`
  background-color: #ffff;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 10px 20px;
  text-align: center;
  @media (max-width: 600px) {
    width: 80%;
  }
`;

const ErrorDiv = styled.div`
  padding: 50px;
  color: #ed0e2f;
  font-weight: bold;
  font-size: 20px;
  @media (max-width: 600px) {
    font-size: 14px;
    padding: 20px;
  }
`;

type ButtonProps = {
  primary?: boolean;
};

const ButtonTextUp = styled.p`
  font-size: 16px;
  color: #ffff;
  text-transform: uppercase;
`;

const ButtonText = styled.p`
  font-size: 16px;
  color: #ffff;
`;

const Button = styled.button<ButtonProps>`
  background-color: ${({ primary }) => (primary ? "#96C11E" : "#7C878E")};
  width: 100%;
  padding: 15px;
  margin-bottom: 20px;
  margin-top: 20px;
  border: none;
  cursor: pointer;
  &:hover {
    background-color: #e3e6e5;
  }
  @media (max-width: 600px) {
    padding: 5px;
    margin-bottom: 10px;
    margin-top: 10px;
  }
`;

const LoginForm = styled.form`
  width: 100%;
  height: 100%;
`;

const Header = styled.header`
  background-color: #ffff;
`;

const Logo = styled.img`
  padding: 5px 0 0 15px;
`;

const Box = styled.div`
  width: 60%;
  background-color: #ffff;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 100px;
  padding: 70px 20px;
  text-align: center;
  @media (max-width: 600px) {
    width: 80%;
  }
`;

const Text = styled.p`
  color: #333f48;
  font-size: 30px;
  font-weight: bold;
  @media (max-width: 600px) {
    font-size: 22px;
  }
`;

const NavLink = styled.p`
  text-align: right;
  font-size: 16px;
  @media (max-width: 600px) {
    font-size: 13px;
  }
`;

const Paragraph = styled.p`
  color: #7c878e;
  font-size: 20px;
  @media (max-width: 600px) {
    font-size: 14px;
  }
`;

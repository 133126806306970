import React from "react";
import styled from "styled-components";
import { MinusCircle } from "@styled-icons/boxicons-solid/MinusCircle";
import { PlusCircle } from "@styled-icons/boxicons-solid/";
import {
  getSeverityColor,
  getSeverityNormalColor,
} from "../utils/getSeverityColor";

type Props = {
  title: string;
  children: React.ReactNode;
  included: boolean;
  toggleInclude?: () => void;
  severity: number;
  severityCount?: number;
};

export function Accordion({
  title,
  children,
  included,
  toggleInclude,
  severity,
  severityCount = 3,
}: Props) {
  let color = getSeverityColor(severity, 1);

  if (severityCount === 3) {
    color = getSeverityNormalColor(severity, 1);
  }

  return (
    <Wrapper>
      <Header>
        {title}
        <IconContainer>
          {toggleInclude && (
            <ReportButton onClick={toggleInclude}>
              {included
                ? "Sisällytetty raporttiin"
                : "Ei sisällytetty raporttiin"}
              {included ? (
                <MinusCircleIcon />
              ) : (
                <PlusCircleIcon style={{ color: "#333F48" }} />
              )}
            </ReportButton>
          )}
        </IconContainer>
      </Header>
      <Content color={color}>
        {included && <ContentDiv>{children}</ContentDiv>}
      </Content>
    </Wrapper>
  );
}

const ReportButton = styled.div`
  border: none;
  font-size: 14px;
  color: #333f48;
  text-transform: none;
  font-weight: normal;
  padding-right: 5px;
`;
const ContentDiv = styled.div`
  padding: 20px;
  img {
    max-width: 100%;
  }
`;

const IconContainer = styled.div`
  float: right;
`;

const PlusCircleIcon = styled(PlusCircle)`
  width: 25px;
  margin-left: 5px;
`;

const MinusCircleIcon = styled(MinusCircle)`
  width: 25px;
  margin-left: 5px;
`;

type ContentProp = {
  color: string;
};
const Content = styled.div<ContentProp>`
  padding: 1px;
  background-color: #ffffff;
  border-left: 10px solid ${({ color }) => color};
  border-bottom: 1px solid #e3e6e5;
  border-right: 1px solid #e3e6e5;
`;

const Header = styled.div`
  /* display: flex; */
  text-transform: uppercase;
  color: #333f48;
  font-size: 14px;
  font-weight: bold;
  align-items: center;
  background-color: #e3e6e5;
  padding: 10px;
  cursor: pointer;
  border: 1px solid #e3e6e5;
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 0 40px 0 40px;
`;

import React from "react";
import styled from "styled-components";

type Props = {
  title: string;
  date: string;
  children: React.ReactNode;
  included: boolean;
};

export function InputAccordion(props: Props) {
  return (
    <Wrapper>
      <Header>
        <Div>{props.title}</Div>
        <Div>
          <DateContent>Raportti luotu: {props.date}</DateContent>
        </Div>
      </Header>
      <Content>
        {props.included && <ContentDiv>{props.children}</ContentDiv>}
      </Content>
    </Wrapper>
  );
}

const DateContent = styled.div`
  float: right;
  text-transform: none;
  font-size: 14px;
  font-weight: normal;
`;

const Div = styled.div`
  width: 50%;
  float: left;
  padding: 10px 10px 10px 0;
  flex: 1;
`;

const ContentDiv = styled.div`
  padding: 20px;
  font-weight: bold;
  font-size: 20px;
`;

const Content = styled.div`
  padding: 1px;
  background-color: #ffffff;
  border: 1px solid #e3e6e5;
`;

const Header = styled.div`
  /* display: flex; */
  text-transform: uppercase;
  color: #333f48;
  font-size: 14px;
  font-weight: bold;
  align-items: center;
  background-color: #e3e6e5;
  padding: 10px;
  cursor: pointer;
  border: 1px solid #e3e6e5;
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 0 40px 0 40px;
`;
